import * as React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Title from "../components/Globals/Title"
import Logo2 from "../images/svg/CSlogo2.inline.svg"
// import Map from "../components/Map/Map"

// const location = {
//   address: "4231 North Dixie Boulevard, Odessa, TX 79762",
//   lat: 31.88573,
//   lng: -102.37434,
// }

const LocationPage = ({ data }) => {
  // const image = getImage(data.image.childImageSharp.gatsbyImageData)
  return (
    <Layout>
      <Seo title="Hours & Location" />
      {/* <BackgroundImg
        img={image}
        title="Visit Us"
        description=""
        styleClass="location-background-image"
      /> */}
      <section className="container location-background">
        <div className="my-5  hero-text-xred">
          <Title title="Hours & Location" />
        </div>
        <div className="col">
          <div className="col-10 col-sm-8 col-md-5 mx-auto">
            <div className=" text-capitalize text-center m-5">
              <p className="hero-text-xred">4231 N Dixie Blvd</p>
              <p className="hero-text-xred">Odessa, TX 79762</p>
              <p className="hero-text-xred">(432) 614 - 6526</p>
              <a
                href="https://www.google.com/maps/dir//Crab+Station+Oyster+Bar,+4231+N+Dixie+Blvd,+Odessa,+TX+79762/@31.885364,-102.3770363,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x86fbc9580319301f:0xd1556bfac211de0f!2m2!1d-102.3747464!2d31.8854916!3e0"
                rel="noreferrer noopenner"
                target="_blank"
                className="btn btn-brand location-btn border border-2 border-danger"
              >
                Get Directions
              </a>
            </div>
            <div className=" text-capitalize text-center m-5">
              <p className="hero-text-xred">
                Sunday - Thursday: 12:00 PM - 10:30 PM
              </p>
              <p className="hero-text-xred">
                Friday - Saturday: 12:00 PM - 11:00 PM
              </p>
              <p className="hero-text-xred">
                <small>Extended Hours on Fight/Game Nights</small>
              </p>
            </div>
            <div className=" text-capitalize text-center ">
              <p className="hours-p-tag text-uppercase">Happy Hours:</p>
              <p className="hero-text-xred">Monday - Friday: 3PM - 7PM</p>
            </div>
            <div className="d-flex mx-auto justify-content-center">
              {/* <div style={{ display: "grid" }}> */}
              <Logo2 />
            </div>
          </div>
          {/* <div className="col-md-5 mx-auto">
            <Map />
          </div> */}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    image: file(relativePath: { eq: "patio.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2000
          quality: 50
          placeholder: NONE
          blurredOptions: { width: 100 }
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }
  }
`

export default LocationPage
